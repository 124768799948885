<template>
  <div class="pt-1 my-1 main-component-content">
    <v-row class="ma-0 pa-0">
      <v-col
        :class="mobile ? 'border-t' : 'border-r'"
        cols="12"
        xs="12"
        sm="12"
        md="8"
        lg="8"
        xl="8"
      >
        <v-container>
          <v-col class="mb-8 mt-0 mx-0 pa-0" align-self="start">
            <div v-if="items[index].image" class="loading-color home-background-card mb-4" :style="'background-image: url(' + items[index].image + ')'"/>
            <h1 class="text-left w-full mb-2">{{ items[index].title }}</h1>
            <p v-html="items[index].content" class="text-left w-full"></p>
            <div v-if="items[index].files">
              <v-btn
                v-for="(file, fileIndex) in items[index].files"
                :key="fileIndex"
                elevation="0"
                large
                class="mb-2 w-full text-transform-none white--text"
                download
                :href="file.url"
                color="tertiary-color"
              >
                {{ file.name }}
                <v-icon
                  right
                  class="ml-2"
                >
                  mdi-download
                </v-icon>
              </v-btn>
            </div>
            <div v-if="items[index].buttons">
              <v-btn
                v-for="(button, buttonIndex) in items[index].buttons"
                :key="buttonIndex"
                elevation="0"
                large
                target="_blank"
                class="mb-2 w-full text-transform-none white--text"
                :href="button.url"
                color="primary-color"
              >
                {{ button.name }}
              </v-btn>
            </div>
            <v-carousel v-if="items[index].carousel" class="mb-8 max-carousel-width rounded">
              <v-carousel-item
                v-for="(item,i) in items[index].carousel"
                :key="i"
                :src="item.src"
                cycle
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
            <div v-if="items[index].video">
              <iframe width="100%" height="831" :src="items[index].video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div v-if="items[index].pdf" class="fixedHeightScroll secondary-color">
              <transition name="slide-fade">
                <div v-if="!pdfLoader" class="pa-5">
                  <span class="white--text">cargando pdf <strong>{{ Math.floor((pdfNumber / items[index].pdf.totalPages) * 100) }}%</strong></span>
                  <spinner-component class="mt-4"/>
                </div>
              </transition>
              <pdf-component v-for="(page, pageIndex) in items[index].pdf.totalPages" :key="pageIndex" :src="items[index].pdf.file" :page="page" @progress="load($event,page,items[index].pdf.totalPages)"></pdf-component>
            </div>
          </v-col>
          <v-divider></v-divider>
        </v-container>
        <div class="welcome-link-group mb-8">
          <v-btn style="background-color: #EA7730; color: white"
                 dark class="mr-md-1" @click="$router.push('home')">Volver</v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <button-component
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          class="mb-2 text-transform-none"
          @whenClick="() => { index = itemIndex; pdfLoader = false; pdfNumber = 0;}"
          :disabled="itemIndex == index"
        >
          {{ item.title }}
        </button-component>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from '@/components/form/Button.vue';
import Spinner from '@/components/animations/Spinner.vue';
import pdf from 'vue-pdf'

export default {
  components: {
    'button-component': Button,
    'pdf-component': pdf,
    'spinner-component': Spinner,
  },
  data() {
    return {
      index: 0,
      pdfLoader: false,
      pdfNumber: 0,
      items: [
        {
          title: 'Informes',
          content: '<strong>Descargar los informes en pdf</strong>',
          files: [
            {
              url: './informes/Evaluación_PEI 2016-2020_Diciembre_2020.pdf',
              name: 'Evaluación PEI 2016 - 2020'
            },
            {
              url: './informes/Prestación_Diagnóstico_Estratégico.pdf',
              name: 'Diagnóstico Estratégico 2020 - 2030'
            },
            {
              url: './informes/informe_de_avance_2021-pei2030.pdf',
              name: 'Informe de avance 2021 - PEI2030'
            },
            {
              url: './informes/2021_informe_pei_anual_.pdf',
              name: '2021 Informe PEI Anual'
            },
            {
              url: './informes/2021_contribucion_poe_objetivos_estrategicos_.pdf',
              name: '2021 Contribucion POE Objetivos Estrategicos'
            },
            {
              url: './informes/2021_informe_de_indicadores_(gobierno_central).pdf',
              name: '2021 Informe de Indicadores (Gobierno Central)'
            },
            {
              url: './informes/2021_informe_de_avance_de_pde.pdf',
              name: '2021 Informe de Avance de PDE'
            },
            {
              url: './informes/2021_informe_avance_poe_.pdf',
              name: '2021 Informe Avance POE'
            },
            {
              url: './informes/2021_informe_cierre_poe.pdf',
              name: '2021 Informe Cierre POE'
            },
            {
              url: './informes/2022_informe_avance_poe_.pdf',
              name: '2022 Informe Avance POE'
            },
            {
              url: './informes/2022_informe_de_pt_(gobierno_central)_.pdf',
              name: '2022 Informe de PT (Gobierno Central)'
            },
            {
              url: './informes/2022_informe_pei_anual_.pdf',
              name: '2022 Informe PEI Anual'
            },
            {
              url: './informes/2022_informe_cierre_poe_2022_.pdf',
              name: '2022 Informe Cierre POE 2022'
            },
            {
              url: './informes/2022_informe_de_indicadores_(gobierno_central)_.pdf',
              name: '2022 Informe de Indicadores (Gobierno Central)'
            },
            {
              url: './informes/2022_contribucion_poe_objetivos_estrategicos_.pdf',
              name: '2022 Contribucion POE Objetivos Estrategicos'
            },
            {
              url: './informes/2022_informe_de_avance_de_pde_.pdf',
              name: '2022 Informe de Avance de PDE'
            },
            {
              url: './informes/2023_informe_avance_poe_.pdf',
              name: '2023 Informe Avance POE'
            },
            {
              url: './informes/2023_informe_pei_anual_.pdf',
              name: '2023 Informe PEI Anual'
            },
            {
              url: './informes/2023_informe_de_avance_de_pde_.pdf',
              name: '2023 Informe de Avance de PDE'
            },
            {
              url: './informes/2023_informe_de_pt_(gobierno_central)_.pdf',
              name: '2023 Informe de PT (Gobierno Central)'
            }
          ],
          carousel: [
            {
              src: require('@/assets/images/stages/stage3-1.jpg'),
            },
            {
              src: require('@/assets/images/stages/stage3-2.jpg'),
            },
            {
              src: require('@/assets/images/stages/stage3-3.jpg'),
            },
            {
              src: require('@/assets/images/stages/stage3-4.jpg'),
            },
          ]
        },
        {
          title: 'Indicadores Estratégicos',
          content: '<strong>Ir al sistema de indicadores estratégicos de la universidad de santiago de chile</strong><br><p>A continuación serás redirigido a una página externa en donde podrás revisar en mayor detalle esta información.</p>',
          buttons: [
            {
              url: 'http://sie.usach.cl/',
              name: 'Indicadores Estratégicos'
            },
          ],
          carousel: [
            {
              src: require('@/assets/images/stages/stage3-1.jpg'),
            },
            {
              src: require('@/assets/images/stages/stage3-2.jpg'),
            },
            {
              src: require('@/assets/images/stages/stage3-3.jpg'),
            },
            {
              src: require('@/assets/images/stages/stage3-4.jpg'),
            },
          ]
        },
        {
          title: 'Contribución Unidades Académicas',
          content: '<strong>Avance PEI 2030 en Unidades Académicas</strong>',
          files: [{
            url: './informe_de_brechas_unidades_academicas.pdf',
            name: 'Informe de avance Unidades Académicas'
          }]
        }
    ],
    };
  },
  methods: {
    load(event,current,total) {
      this.pdfNumber = current;
      if (event == 1 && current > ((total/5)*4)) {
        this.pdfLoader = true;
      }
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
};
</script>

<style scoped>
.main-component-content {
  min-height: 100vh;
  width: 100%;
}
.welcome-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.welcome-link {
  cursor: pointer;
  text-decoration: underline;
}
.welcome-link:not(:last-child) {
  margin-right: 1em;
}
.border-t {
  border-top: 1px solid rgba(0,0,0,.12);
}
.border-r {
  border-right: 1px solid rgba(0,0,0,.12);
}
.text-transform-none {
  text-transform: none;
}
.home-background-card {
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.fixedHeightScroll {
  height: 80vh;
  overflow-y: scroll;
  border: 5px solid #ffffff;
  border-radius: 10px;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
