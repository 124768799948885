<template>
  <div class="pt-1 my-1 main-component-content">
    <v-row class="ma-0 pa-0">
      <v-col
        :class="mobile ? 'border-t' : 'border-r'"
        cols="12"
        xs="12"
        sm="12"
        md="8"
        lg="8"
        xl="8"
      >
        <v-container>
          <v-col class="mb-8 mt-0 mx-0 pa-0" align-self="start">
            <div v-if="items[index].image" class="loading-color home-background-card mb-4" :style="'background-image: url(' + items[index].image + ')'"/>
            <h1 class="text-left w-full mb-2">{{ items[index].title }}</h1>
            <p v-html="items[index].content" class="text-left w-full"></p>
            <div v-if="items[index].video">
              <iframe width="100%" height="831" :src="items[index].video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div v-if="items[index].files">
              <v-btn
                  v-for="(file, fileIndex) in items[index].files"
                  :key="fileIndex"
                  elevation="0"
                  large
                  class="mb-2 w-full text-transform-none white--text"
                  download
                  :href="file.url"
                  color="tertiary-color"
              >
                {{ file.name }}
                <v-icon
                    right
                    class="ml-2"
                >
                  mdi-download
                </v-icon>
              </v-btn>
            </div>
            <v-btn
              v-if="items[index].pdf"
              elevation="0"
              large
              class="mb-2 w-full text-transform-none white--text"
              download
              :href="items[index].pdf.file"
              color="tertiary-color"
            >
              Descargar PDF Plan Estratégico Institucional
              <v-icon
                right
                class="ml-2"
              >
                mdi-download
              </v-icon>
            </v-btn>
            <v-btn
              v-if="items[index].download"
              elevation="0"
              large
              class="mb-2 w-full text-transform-none white--text"
              @click="download()"
              color="tertiary-color"
            >
              Descargar PDF Plan de Desarrollo Unidad Académica
              <v-icon
                right
                class="ml-2"
              >
                mdi-download
              </v-icon>
            </v-btn>
            <div v-if="items[index].pdf" class="fixedHeightScroll secondary-color">
              <transition name="slide-fade">
                <div v-if="!pdfLoader" class="pa-5">
                  <span class="white--text">cargando pdf <strong>{{ Math.floor((pdfNumber / items[index].pdf.totalPages) * 100) }}%</strong></span>
                  <spinner-component class="mt-4"/>
                </div>
              </transition>
              <pdf-component v-for="(page, pageIndex) in items[index].pdf.totalPages" :key="pageIndex" :src="items[index].pdf.file" :page="page" @progress="load($event,page,items[index].pdf.totalPages)"></pdf-component>
            </div>
          </v-col>
          <v-divider></v-divider>
        </v-container>
        <div class="welcome-link-group mb-8">
          <v-btn style="background-color: #EA7730; color: white"
                 dark class="mr-md-1" @click="$router.push('home')">Volver</v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <button-component
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          class="mb-2 text-transform-none"
          @whenClick="() => { index = itemIndex; pdfLoader = false; pdfNumber = 0; if(items[index].link){openlink(items[index].link);}}"
          :disabled="itemIndex == index">
          {{ item.title }}
        </button-component>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import Button from '@/components/form/Button.vue';
import Spinner from '@/components/animations/Spinner.vue';
import pdf from 'vue-pdf'
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    'button-component': Button,
    'pdf-component': pdf,
    'spinner-component': Spinner,
  },
  data() {
    return {
      index: 0,
      pdfLoader: false,
      pdfNumber: 0,
      items: [
        {
          title: 'Plan Estratégico Institucional 2020-2030',
          content: '<p>La Universidad de Santiago de Chile se proyecta a través de sus cinco ejes estratégicos institucionales 2020 - 2030. Sus respectivos objetivos, están articulados con los respectivos Planes de Desarrollo de los Departamentos, Escuelas, Programas y Facultades de la Universidad. En esta sección podrá visualizar los desafíos - institucionales y de unidades académicas - que acompañarán la planificación estratégica institucional en la próxima década. Los respectivos informes representan el marco de contribución de sus respectivos Planes Operativos.</p>',
          video: 'https://www.youtube.com/embed/jD5uJwz5oIE',
        },
        {
          title: 'Documentos Planes Estratégicos',
          content: '<p></p>',
          download: true,
          pdf: {
            file: '/PEI_INSTITUCIONAL_LATEST.pdf',
            totalPages: 48,
          },
        },
        {
          title: 'Página web PEI2030',
          link: 'https://pei.usach.cl'
        },
        {
          title: 'PEI Históricos',
          content: '',
          download: false,
          files: [{
            url: '/pei_historico/pei_1994_2002.pdf',
            name: 'PEI 1994-2002'
          },{
            url: '/pei_historico/pei_2006_2010.doc',
            name: 'PEI 2006-2010'
          },{
            url: '/pei_historico/pei_2011_2015.pdf',
            name: 'PEI 2011-2015'
          },{
            url: '/pei_historico/pei_2016_2020.pdf',
            name: 'PEI 2016-2020'
          }]
        }
      ],
    };
  },
  methods: {
    ...mapActions('main', [
      'getDocumentoByTipoAndCc'
    ]),
    openlink(link){
      console.log(link);
      window.open(link, '_blank');
    },
    download() {
      this.getDocumentoByTipoAndCc(this.info.id)
        .then(response => {
          //console.log(response.data.response);
          var saveData = (function () {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              return function (data, fileName) {
                  var json = JSON.stringify(data),
                      blob = new Blob([data], {type: 'application/octet-stream'}),
                      url = window.URL.createObjectURL(blob);
                  json;
                  a.href = url;
                  a.download = fileName;
                  a.click();
                  window.URL.revokeObjectURL(url);
              };
          }());
          saveData(response.data.response, this.info.ccNombre + ".pdf");
        })
        .catch(err => console.log(err));
    },
    load(event,current,total) {
      this.pdfNumber = current;
      if (event == 1 && current > ((total/5)*4)) {
        this.pdfLoader = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info']
    }),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
};
</script>

<style scoped>
.main-component-content {
  min-height: 100vh;
  width: 100%;
}
.welcome-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.welcome-link {
  cursor: pointer;
  text-decoration: underline;
}
.welcome-link:not(:last-child) {
  margin-right: 1em;
}
.border-t {
  border-top: 1px solid rgba(0,0,0,.12);
}
.border-r {
  border-right: 1px solid rgba(0,0,0,.12);
}
.text-transform-none {
  text-transform: none;
}
.home-background-card {
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.fixedHeightScroll {
  height: 80vh;
  overflow-y: scroll;
  border: 5px solid #ffffff;
  border-radius: 10px;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
