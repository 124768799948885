<template>
  <div class="pt-1 my-1 main-component-content">
    <v-row class="ma-0 pa-0">
      <v-col
          :class="mobile ? 'border-t' : 'border-r'"
          cols="12"
          xs="12"
          sm="12"
          md="8"
          lg="8"
          xl="8">
        <v-container>
          <v-col class="mb-8 mt-0 mx-0 pa-0" align-self="start">
            <h1 class="text-left w-full mb-2">{{ items[index].title }}</h1>
            <p v-html="items[index].content" class="text-left w-full"></p>
            <div v-if="items[index].files">
              <v-btn
                  v-for="(file, fileIndex) in items[index].files"
                  :key="fileIndex"
                  elevation="0"
                  large
                  class="mb-2 w-full text-transform-none white--text"
                  download
                  :href="file.url"
                  color="tertiary-color"
              >
                {{ file.name }}
                <v-icon
                    right
                    class="ml-2"
                >
                  mdi-download
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-container>
        <div class="welcome-link-group mb-8">
          <v-btn style="background-color: #EA7730; color: white"
                 dark class="mr-md-1" @click="$router.push('home')">Volver</v-btn>
        </div>
      </v-col>
      <v-col
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
      >
        <button-component
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            class="mb-2 text-transform-none"
            @whenClick="() => { index = itemIndex; pdfLoader = false; pdfNumber = 0;}"
            :disabled="itemIndex === index">
          {{ item.title }}
        </button-component>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from '@/components/form/Button.vue';
export default {
  name: "Others",
  components: {
    'button-component': Button,
  },
  data(){
    return{
      index: 0,
      pdfLoader: false,
      pdfNumber: 0,
      items: [
        {
          title: 'Resoluciones',
          content: '<strong>Descargar resoluciones en pdf</strong>',
          files: [
            {
              url: '/resoluciones/resolucion_0898_proceso_lineamientos_estrategicos.pdf\n',
              name: 'Resolucion 0898/2018 Proceso de Lineamientos Estrategicos'
            },
            {
              url: '/resoluciones/resolucion_8114_pei_2017.pdf',
              name: 'Resolución 8114/2017 Establece Reglamento Proceso PEI Universidad de Santiago'
            },
            {
              url: '/resoluciones/resolucion_pei_2020_2030.pdf',
              name: 'Resolución 539/2021 Aprueba PEI 2020-2030'
            },
            {
              url: '/resoluciones/resolucion_3925_2022_crea_unidad_funcional_denominada_unidad_de_apoyo_para_la_interculturalidad.pdf',
              name: 'Resolución 3925/2022 Crea Unidad de Apoyo para la Interculturalidad'
            },
            {
              url: '/resoluciones/resolucion_8297_estructura_organica.pdf',
              name: 'Resolución 8297 Estructura Organica'
            },
            {
              url: '/resoluciones/apl_II_campus_sustentable_nov21_firmado_por_autoridades.pdf',
              name: 'Acuerdo Produccion Limpia - Educación Superior Sustentable'
            }
          ]
        }],
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
          this.$vuetify.breakpoint.md ||
          this.$vuetify.breakpoint.lg ||
          this.$vuetify.breakpoint.xl
      );
    },
  },
}
</script>

<style scoped>

</style>