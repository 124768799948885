<template>
  <div class="mb-8 my-16 py-6 px-4">
    <v-tabs v-model="tabs">
      <v-tab>Planificación Estratégica</v-tab>
      <v-tab>Diagnóstico Estratégico</v-tab>
      <v-tab>Otros</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <strategicPlanningComponent/>
      </v-tab-item>
      <v-tab-item>
        <strategicDiagnosticComponent/>
      </v-tab-item>
      <v-tab-item>
        <othersComponent/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import StrategicPlanning from "@/components/Informations/StrategicPlanning.vue";
import StrategicDiagnostics from "@/components/Informations/StrategicDiagnostics.vue";
import Others from "@/components/Informations/Others.vue";

export default {
  name: "Informations",
  components: {
    'strategicPlanningComponent': StrategicPlanning,
    'strategicDiagnosticComponent': StrategicDiagnostics,
    'othersComponent': Others
  },
  data(){
    return {
      tabs: null,
    }
  },
}
</script>

<style scoped>

</style>